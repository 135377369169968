import { StatusBar } from 'expo-status-bar';
import * as React from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
//import { Provider as PaperProvider } from 'react-native-paper';

import useCachedResources from './hooks/useCachedResources';
import useColorScheme from './hooks/useColorScheme';
import Navigation from './navigation';
//import { Appbar } from 'react-native-paper';
import { UserContextProvider, useUserContext } from './context/UserContext';
import { useState } from 'react';
import * as Font from 'expo-font';






export default function App() {
  const { user, dispatch } = useUserContext();
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();

  
  const fetchFonts = () => {
    return Font.loadAsync({
    'Roboto-Regular': require('./assets/fonts/Roboto-Regular.ttf')
    });
    };
   fetchFonts();
  if (!isLoadingComplete) {
    return null;
  } else {
    return (
      <SafeAreaProvider>
        <UserContextProvider>
        <Navigation colorScheme={colorScheme} />
        </UserContextProvider>
        </SafeAreaProvider>
    );
  }
}