import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import { Platform, TouchableOpacity, Linking, Button } from 'react-native';
import { StyleSheet, Dimensions, Image, Alert, ScrollView } from 'react-native';
//import MapView, { Callout } from 'react-native-maps';
//import { Marker } from 'react-native-maps';
import { InfoCards } from '../components/InfoCards';
import { Text, View } from '../components/Themed';
import { RootTabScreenProps } from '../types';
import { fetchOrders, OrderInfo } from '../utils/UGAPIService'; //WooCommerceService';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import {  useUserContext } from '../context/UserContext';
import LoginScreen from './LoginScreen';
import { RemoveScroll } from 'react-remove-scroll';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'


import {styles} from '../constants/stylesUG';
import { FontAwesome } from '@expo/vector-icons';

//import '../node_modules/leaflet/dist/leaflet.css';
import L, { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";


const WIDTH=Math.min(Dimensions.get('window').width,600);
const HEIGHT=Dimensions.get('window').height;









const SharePlantationButton = () => {
return (
  <>
  <Text>Share your status!</Text>
<Button title='Share' onPress={ () => {
  const url='https://yle.fi/uutiset/3-11300232';
  Linking.openURL(url);
  } } /> 
  </>
)}

const SharePlantationButtonOLI = () => {
  return (
  <Button title='share' onPress={ async (e) => {
    const url='';
     // Checking if the link is supported for links with custom URL scheme.
     const supported = await Linking.canOpenURL(url);
  
     if (supported) {
       // Opening the link with some app, if the URL scheme is "http" the web link should be opened
       // by some browser in the mobile
       await Linking.openURL(url);
     } else {
       Alert.alert(`Don't know how to open this URL: ${url}`);
     }
   }}
   >
      <FontAwesome name="share" color={'red'} size={24}/> 
      </Button>
  )}
  
// TreePlantationInfo Component
type TreePlantationInfoData = {
  data: {
    treeType: string;
    amount: number;
    latitude: number;
    longitude: number;
    client?: string;
    clientLogo?: string;
    planter?: string;
    plantingDate?: string;
    absorbingCO2?: number;
    status: string;
  }
};


const TreePlantationInfoCard = (props:{infoData:TreePlantationInfoData;setRegion(region:TreeMapProps):void}) => {
  const colorScheme = useColorScheme();

  return (
    <View style={{...styles.cardTransparent, backgroundColor: Colors[colorScheme].background }}>
      <View style={{flexDirection:'row'}}>          
    <Image source={require('../assets/images/pine-tree-5085.png')}/>
    <Text style={{ width:WIDTH/3, fontSize: 14, color: Colors[colorScheme].text }}>{props.infoData.data.treeType}</Text>
      <View style={{flexDirection:'column', paddingLeft:5, width:WIDTH/6}}>
        <Text style={{fontSize:10, textAlign: 'center'}}>{props.infoData.data.amount} trees</Text>
        <Text style={{fontSize:8, textAlign: 'center'}}>{ (props.infoData.data.plantingDate?props.infoData.data.plantingDate.substring(0,10):'pending') }</Text>
      { (props.infoData.data.latitude!==0?
      <TouchableOpacity style={{alignItems: "center", height: 32, width: 64, padding: 10}}
      onPress={ () => { props.setRegion( { region: {
        latitude: props.infoData.data.latitude,
        longitude: props.infoData.data.longitude,
        latitudeDelta: 60.0,
        longitudeDelta: 10.0,}
      }); console.log('Moving map to '+props.infoData.data.latitude) } } >
        <Image style={{height:16, width:32}} source={require('../assets/images/map-1272165_640.png') } />
        
        </TouchableOpacity>
        :<View style={{width:64}}></View>)}
      </View>

        <View style={{flexDirection:'column', paddingLeft:5, width:WIDTH/6}}>
          <Text style={{fontSize:12, textAlign: 'right'}}>CO2 kg/year</Text>
          <Text style={{fontSize:14, textAlign: 'right'}}> {props.infoData.data.absorbingCO2}</Text>
        </View>
      </View>
      </View>

  )
}



type TreeMapProps = { region: {
  latitude: number;
  longitude: number;
  latitudeDelta: number;
  longitudeDelta: number;
}};


// TreeMap (functional) Component
const TreeMapWeb = (props: {treeMapProps:TreeMapProps; plantationInfos:TreePlantationInfoData[]}) => {
  const [region, setRegion] = useState(props.treeMapProps.region);
  useEffect(() => {
    setRegion(props.treeMapProps.region);
  }, [props.treeMapProps.region]);

  const pinColor='green'; //'#80b727';
  const position:any=[props.treeMapProps.region.latitude,props.treeMapProps.region.longitude];
 
  const treeIcon:Icon = new L.icon({
    iconUrl: require("../assets/images/mapmarkerug.png"),
    iconAnchor:   [22, 51],
    iconSize: [64, 51],
  });

  return (
    <>
      <Text style={{fontSize: 16, textAlign: 'center'}}>Plantations on map</Text>
      { (Platform.OS !== 'web')?
        <Text>TBD MOBILE MAP</Text>
      :
      <MapContainer center={position} zoom={1} scrollWheelZoom={false}
      maxZoom={20} style={{ width:WIDTH, height:HEIGHT/4}}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        { props.plantationInfos.map((plantationInfo:TreePlantationInfoData,i:number) => {
          //console.log('plant on map '+plantationInfo.data.treeType+' at '+plantationInfo.data.latitude+', '+plantationInfo.data.longitude)
          if(plantationInfo.data.latitude!=0 && plantationInfo.data.longitude!=0) {
            return (
              <Marker position={ [plantationInfo.data.latitude, plantationInfo.data.longitude] } key={i} icon={treeIcon}>
              <Popup>
                { plantationInfo.data.treeType } { plantationInfo.data.amount } trees
              </Popup>
            </Marker>
          );
            //} else {
            //  return ( <></> );
            }
        })
      }
      </MapContainer>
      }
      </>
      );
}


// TreeMap (functional) Component
const TreeMapMobile = (props: {treeMapProps:TreeMapProps; plantationInfos:TreePlantationInfoData[]}) => {
/*  const [region, setRegion] = useState(props.treeMapProps.region);
  useEffect(() => {
    setRegion(props.treeMapProps.region);
  }, [props.treeMapProps.region]);

  const pinColor='green'; //'#80b727';
 
  return (
    <View style={styles.title}>
      <Text style={{fontSize: 16, textAlign: 'center'}}>Plantations on map</Text>
      { (Platform.OS === 'web')?
        <Text>TBD WEB MAP</Text>
      :
  <MapView style={styles.map} 
    showsPointsOfInterest={false}
    showsBuildings={false}
    loadingEnabled={true}
    initialRegion={region} 
    region={region}
  >

    { props.plantationInfos.map((plantationInfo:TreePlantationInfoData,i:number) => {
          { //console.log('plant on map '+plantationInfo.data.treeType+' at '+plantationInfo.data.latitude+', '+plantationInfo.data.longitude)
          }
          return (
          <Marker key={i} 
            coordinate={{ 
              latitude : plantationInfo.data.latitude,
              longitude: plantationInfo.data.longitude }}
            pinColor={'green'}
              >
                  <Callout style={{width:120}}>
                    <View style={{flexDirection: 'row'}}>
                      <Text style={{width:60}}>{ plantationInfo.data.treeType } { plantationInfo.data.amount } trees</Text>
                      <IconButton icon='cart-plus' color="#00aa00" onPress={ ()=> Alert.alert('BUTPRESS') }></IconButton>
                    </View> 
                </Callout>
                </Marker>
          )
        })
      }


  </MapView>
}
      </View>
      );
      */
     return (
       <></>
     )
}

TreeMapWeb.defaultProps =  { region: {
  latitude: 37.78825,
  longitude: 22.4324,
  latitudeDelta: 60.0,
  longitudeDelta: 10.0,
}};
// TreeMap component ends here



// MapScreen (functional) Component
export default function HomeScreen({ navigation }: RootTabScreenProps<'Home'>) {
  const [plantationInfos, setPlantationInfos] = useState<TreePlantationInfoData[]>([]);
  const colorScheme = useColorScheme();
  const { user,dispatch } = useUserContext();
  const [totalCO2e,setTotalCO2e]=useState(0.0);

  useEffect(() => {
    fetchOrders(user.token)
      .then((orderInfos:OrderInfo[]) => {
        //console.log(orderInfos);
        const pInfos=new Array();
        let calcCO2e=0.0;
        orderInfos.forEach(order => {
          const orderCO2e=(order.productCO2e?order.productCO2e:0)*order.quantity*(order.pcs?order.pcs:1);
          pInfos.push({ data: {treeType:order.productName,amount:order.quantity,treeCount:order.quantity*(order.pcs?order.pcs:1),latitude:order.latitude,longitude:order.longitude,client: 'Donna Persona', planter:'HELPlanters',plantingDate:order.orderDate,absorbingCO2:orderCO2e,status:order.status} });
          calcCO2e=calcCO2e + orderCO2e;
        });
        setTotalCO2e(calcCO2e);        
        setPlantationInfos(pInfos);
      })
      .catch((reason:any) => 
      console.log("Error: "+reason));
      //setProducts(oldProducts);
  },[user]); // only update on loggedInUser change

  
 //const MapScreen = () => {
  const [treeMapProps, setRegion] = useState( { region: 
    { 
    latitude: 24.78825,
    longitude: 60.4324,
    latitudeDelta: 60.0,
    longitudeDelta: 10.0,
  }
});
  if(user!==undefined && user.id>-1) {
    return (
      <RemoveScroll>
    <ScrollView style={{ background: "linear-gradient(#56949f,#005914)" }}>
     <View style={styles.container} > 
     <View style={styles.containerCard}>
       <Text style={{fontSize:10}}>Tähän tulee profiilikortti käyttäjätiedot kevyesti, tämän hetkinen CO2e, jakolinkit yms.</Text>
       <Text style={styles.title}>Logged in as {user.username} </Text>  
       <Button title="Log out" onPress={()=>dispatch({type: 'logoutUser'})} />
        <Text>Your plantations' projected CO2 absorbtion is</Text>
        <Text style={ styles.title } >{totalCO2e.toFixed(2)} kg/year</Text>
        <SharePlantationButton />
      </View>
      <View style={styles.containerCard} >
        <InfoCards contentURL='' showCarousel={false} itemIndex={0}/>
        </View>

      <View style={styles.containerCard} >
      <TreeMapWeb treeMapProps={treeMapProps} 
        plantationInfos={plantationInfos}
      />
      </View>
      <View style={styles.containerCard} >
      <Text style={styles.title}>plantations ({plantationInfos.length})</Text>
      <ScrollView style={styles.scrollView} >
        { plantationInfos.map((plantationInfo,i) => {
          {console.log('plant '+plantationInfo.data.treeType)}
          return (
          <TreePlantationInfoCard
            key={i}
            infoData={plantationInfo}
            setRegion={setRegion}
          />
          )
        })}
      </ScrollView>
      </View>

      <View style={styles.containerCard} >
        <InfoCards contentURL='' showCarousel={false} itemIndex={1}/>
        </View>
        <View style={styles.containerCard} >
        <InfoCards contentURL='' showCarousel={false} itemIndex={2}/>
        </View>
        <View style={styles.containerCard} >
        <InfoCards contentURL='' showCarousel={false} itemIndex={3}/>
        </View>
      </View>
    </ScrollView>
    </RemoveScroll>    )
  } else {
    return (
      <View style={{ background:  "linear-gradient(#56949f,#005914)", width:'100%', height: '100%', margin:0}} >
      <LoginScreen />
      </View>
    )
  }
}
//export default MapScreen;

