import * as React from 'react';
import {useCallback, useContext, useEffect, useState} from 'react';
import { ImageBackground, TouchableOpacity, Linking } from 'react-native';
import { StyleSheet, Dimensions, Button, Image, Alert, ScrollView } from 'react-native';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import { Card, IconButton, Paragraph, Title } from 'react-native-paper';
import { Text, View } from './Themed';


import { fetchPosts, PostInfo } from '../utils/WordPressService';

const WIDTH=Math.min(600,Dimensions.get('window').width);
const HEIGHT=Dimensions.get('window').height;

interface ItemProps {
  title: string;
  text: string;
  bgImage?: string;
  linkURL?: string;
}

// interface Props {
//   posts?: ItemProps;
// }

interface State {
  activeIndex: number;
  posts: ItemProps[];
}




const initialIsFetching = false;






// TreenityActionCarousel Component
export const InfoCards = (props: {contentURL:string, showCarousel?:boolean, itemIndex?:number}) => {
  const [posts, setPosts] = useState<PostInfo[]>([]);
  const [ isFetching, setIsFetching] = useState(initialIsFetching);

  useEffect(() => {
    if(isFetching)
      return;
    const oldPosts=posts.copyWithin(100,1);
    setIsFetching(true);
    fetchPosts()
      .then((postInfos:PostInfo[]) => {
        setPosts(postInfos);
        setIsFetching(false);
      })
      .catch((reason:any) => {
        console.log("Error: "+reason);
        setPosts(oldPosts);
        setIsFetching(false);
      })
  },[]);
  
    if(props.itemIndex === undefined || props.itemIndex >=posts.length)
    {

      return (
        <>
        </>
      )
    }
  
    const post=posts[(props.itemIndex?props.itemIndex:0)];
    return (
        <View style={{  width: WIDTH, flexDirection:'column', justifyContent: 'center'}}>
            <View style={{
              paddingTop: 0,
              marginLeft: 0,
              marginRight: 0, flex:-1 }}>
            { (post.bgImage?
              <ImageBackground resizeMode='cover' imageStyle= 
              {{opacity:0.8}} style={{  backgroundColor: "rgba(32,36,100,0.6)" }} source={{uri: post.bgImage}} >
                <Text style={{flexWrap: 'wrap',marginTop: 5, marginLeft: 10, marginRight:10, fontFamily: "Roboto-Regular", fontSize: 24, color: "white",  shadowColor: "black", textShadowOffset: {width: 2, height: 2}, textShadowRadius: 5, textShadowColor: 'black',}}>{post.title}</Text>
                <Text style={{ flexShrink: 1 ,marginLeft: 10, marginRight: 10, fontFamily: "Roboto-Regular", fontSize: 16, color: "white", shadowColor: "black", textShadowOffset: {width: 2, height: 2}, textShadowRadius: 5, textShadowColor: 'black',}}>{post.text}</Text>
              </ImageBackground>
              : <View style={{ backgroundColor:'#008811ff',}}>
                <Text style={{margin: 5, fontFamily: "Roboto-Regular", fontSize: 16, color: "white", shadowColor: "black", textShadowOffset: {width: 2, height: 2}, textShadowRadius: 10, textShadowColor: 'black',}}>{post.title}</Text>
                <Text style={{margin: 5, fontFamily: "Roboto-Regular", fontSize: 12, color: "white", shadowColor: "black", textShadowOffset: {width: 2, height: 2}, textShadowRadius: 10, textShadowColor: 'black',}}>{post.text}</Text>
                { (post.linkURL?<Button title='Buy now!' onPress={() => console.log('Buy now!')} />: null) }
                </View>
              )}
            </View>
        </View>
    )

  }
  