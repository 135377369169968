import * as React from 'react';
import { Dimensions, Platform, ScrollView, StyleSheet, Image, View } from 'react-native';
import { WebView } from 'react-native-webview';
import { RemoveScroll } from 'react-remove-scroll';

import { RootTabScreenProps } from '../types';

//const WIDTH=Dimensions.get('window').width;
//const HEIGHT=Dimensions.get('window').height;

import { styles, HEIGHT,WIDTH } from '../constants/stylesUG';


export default function CertificatesScreen({ navigation }: RootTabScreenProps<'Certificates'>) {


  return (
    <RemoveScroll>
    <ScrollView style={{ background: "linear-gradient(#56949f,#005914)" }}>
    <View style={styles.container} >
      <View style={styles.containerCard}>
    { Platform.OS === 'web'?
    <Image style={{ width:Math.min(WIDTH,600), height: HEIGHT-120 }} source={{uri: 'https://cdn.pixabay.com/photo/2016/11/07/16/37/document-1806227_960_720.jpg'}} />
    :
    <>
      <WebView style={{ width: WIDTH }} source={{uri: 'https://cdn.pixabay.com/photo/2016/11/07/16/37/document-1806227_960_720.jpg' }} > 
      </WebView>
    </>
    }
    </View>
    </View>
    </ScrollView>
    </RemoveScroll>
  );
}

