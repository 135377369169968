import { StyleSheet, Dimensions } from 'react-native';


export const WIDTH=Math.min(Dimensions.get('window').width,600);
export const HEIGHT=Dimensions.get('window').height;

export const styles = StyleSheet.create({
  container: {
    width: Math.min(600,WIDTH),
    marginTop:30,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'transparent',
    height: HEIGHT-0,
  },
  scrollView: {
    width: Math.min(600,WIDTH),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginHorizontal: 20,
    paddingVertical: 20,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    justifyContent: 'center',
    textAlign: 'center',
  },
  formFieldLabelSmall: {
    height: 10,
    fontSize:12,
    fontWeight:'normal',
    textAlign:'left',
    color: 'white',
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5  
  },
  productTitle: {
    fontSize: 20,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    justifyContent: 'center',
    textAlign: 'center',
  },
  productText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    fontWeight: 'normal',
    justifyContent: 'flex-start',
    textAlign: 'left',
    marginLeft: 15,
    marginRight: 15,
  },
  productImage: {
    width: 300,
    height: 200,
    margin:10,
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
  map: {
    width: Math.min(600,WIDTH),
    marginLeft: 'auto',
    marginRight: 'auto',
    height: WIDTH / 3  * 2,
  },
  callout: {
    width: 100,
    height: 100,
  },
  button: {
    width: 60,
    height: 40,
    paddingHorizontal: 12,
    marginHorizontal: 10,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginVertical: 20,
    backgroundColor: 'transparent',
  },
  containerCard: {
    marginTop: 5,
    marginBottom: 5,
    width: Math.min(600,WIDTH),
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    borderColor: 'white',
    borderWidth: 3,
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'flex-start',
    shadowColor: 'black',
    elevation: 5,
//    backgroundColor: 'white',
  },
  cardTransparent: {
    backgroundColor: 'transparend',
  },
  textInput: {
    height: 40,
    margin: 12,
    borderWidth: 1,
    padding: 10,
  },
});


