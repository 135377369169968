import { Buffer } from 'buffer';
import { sourceURL } from '../constants/serverConfig';


/*export type UserData = {
    id: number;
    username: string;
    token: string;
};
*/
import { UserData } from '../context/UserContext';


const postURL='https://ug-api.cappgw.com/ug/auth'

export const loginUser = async(username:string, password: string) => {
  console.log('Trying to login at '+postURL+' user '+username);
  const response = await fetch(postURL,
    {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password
      })
    }
  );
  console.log('Get response '+response);
  let loginData = await response.json();
  console.log(loginData);
    
  const userData:UserData = {
      id: loginData.data.id,
      username: loginData.data.displayName,
      token: loginData.data.token

  };
  return userData;
};

export const logoutUser = async() => {
  const userData:UserData = {
    id: -1,
    username: '',
    token: '',
  };
  return userData;
};

