
import React from 'react';
import { Image,Text } from 'react-native';

export function LogoTitle() {
    return (
  
      <Image
        resizeMode= 'contain'
        style={{ position:'absolute',height: 64, width:64 }}
        source={require( '../assets/images/logo3dgreen128x128shadow.png')}
      />
      
   
    );
  }