import * as React from 'react';
import { useState } from 'react';
import { Dimensions, StyleSheet, Button, ScrollView, Platform, Switch } from 'react-native';
//import { Title, Paragraph, Card } from 'react-native-paper';
//import {Button} from 'react-native-paper';

import { Text, View } from '../components/Themed';

import { RootTabScreenProps } from '../types';



const WIDTH=Math.min(Dimensions.get('window').width,600);
const HEIGHT=Dimensions.get('window').height;

import { styles } from '../constants/stylesUG';


import { TextInput } from 'react-native';
import { RemoveScroll } from 'react-remove-scroll';
import { useUserContext, UserData } from '../context/UserContext';
import useColorScheme from '../hooks/useColorScheme';
import { LogoTitle } from '../components/LogoTitle';



export default function ProfileSettingsScreen({ navigation }: RootTabScreenProps<'ProfileSettings'>) {
  const colorScheme = useColorScheme();
  const { user,dispatch } = useUserContext();

  const [totalCO2e, setTotalCO2e] = useState(0.0);
  const [vehiclesTotalCO2e, setvehiclesTotalCO2e] = useState(0.0);
  const [flightsTotalCO2e, setFlightsTotalCO2e] = useState(0.0);

  const [petrolVehicleMileageString, onPetrolVehicleMileage] = useState('');
  const [dieselVehicleMileageString, onDieselVehicleMileage] = useState('');

  const [domesticFlightsMileageString,onDomesticFlightsMileage] = useState('');
  const [continentalFlightsMileageString,onContinentalFlightsMileage] = useState('');
  const [interContinentalFlightsMileageString,onIntercontinentalFlightsMileage] = useState('');


  const [phase,setPhase] = useState('Vehicles');


  function parseFloatOrZero(text:string) {
    const num=parseFloat(text);
    return (num)?num:0;    
  }


  const calcCO2 = () => {

    const vehiclesCO2e=parseFloatOrZero(petrolVehicleMileageString)*1/8.47458+parseFloatOrZero(dieselVehicleMileageString)*1/10.30928;
    setvehiclesTotalCO2e(vehiclesCO2e);

    const flightsCO2e=
      parseFloatOrZero(domesticFlightsMileageString)*1/4.63963 +
      parseFloatOrZero(continentalFlightsMileageString)*1/5.54353 +
      parseFloatOrZero(interContinentalFlightsMileageString)*1/7.25109;
    setFlightsTotalCO2e(flightsCO2e);

    setTotalCO2e(vehiclesCO2e+flightsCO2e);
  };

  const [isPrivacyEnabled, setIsPrivacyEnabled] = useState(false);
  const togglePrivacySwitch = () => setIsPrivacyEnabled(previousState => !previousState);

  const [isDarkEnabled, setIsDarkEnabled] = useState(false);
  const toggleThemeSwitch = () => setIsDarkEnabled(previousState => !previousState);

  
  return (
    <RemoveScroll>
    <ScrollView style={{ background: "linear-gradient(#56949f,#005914)" }}>
      <LogoTitle />
    <View style={styles.container} >
      <View style={styles.containerCard}>
      <View style={StyleSheet.flatten([styles.container,{ flexDirection:'row',flex:1}])} >       
        <Text style={StyleSheet.flatten([styles.productTitle,{ flex:1,textAlign:'left',marginLeft:10 }])}>Profile</Text>
        </View>
          <View style={{flexDirection:'column', width:WIDTH/2}} >
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>User</Text>
              <TextInput style={styles.textInput} editable = {false} defaultValue={user.username} />
            </View>
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>Privacy</Text>
              <View style={{flexDirection:'row'}} >
                <Switch
                  trackColor={{ false: "#767577", true: "#81b0ff" }}
                  thumbColor={isPrivacyEnabled ? "#f5dd4b" : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={togglePrivacySwitch}
                  value={isPrivacyEnabled}
                />
                <Text style={{...styles.productText,textAlign: 'left'}}>{isPrivacyEnabled?'Show my plantations to other users':'Hide my plantations from other users'}</Text>
              </View>
            </View>
            
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>Display theme</Text>
              <View style={{flexDirection:'row'}} >
                <Switch
                  trackColor={{ false: "#767577", true: "#81b0ff" }}
                  thumbColor={isDarkEnabled ? "#f5dd4b" : "#f4f3f4"}
                  ios_backgroundColor="#3e3e3e"
                  onValueChange={toggleThemeSwitch}
                  value={isDarkEnabled}
                />
                <Text style={{...styles.productText,textAlign: 'left'}}>{isDarkEnabled?'Light':'Dark'}</Text>
              </View>
            </View>
            
            <View>
            <Text style={{...styles.productText,textAlign: 'left'}}> </Text>
              <Text style={{...styles.productText,textAlign: 'left'}}>(other profile info here?)</Text>
            </View>
            <Button title='Save settings' onPress={() => { setPhase('None');}} ></Button>
          </View>
        </View>
 
          </View>
          </ScrollView>
          </RemoveScroll>
  
  );
}

