/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
  prefixes: [Linking.makeUrl('/')],
  config: {
    screens: {
      Root: {
        screens: {
          Home: {
            screens: {
              HomeScreen: 'home',
            },
          },
          Certificates: {
            screens: {
              CertificatesScreen: 'certificates',
            },
          },
          Products: {
            screens: {
              ProductsScreen: 'products',
            },
          },
          Login: {
            screens: {
              LoginScreen: 'login',
            },
          },
          CO2Calculator: {
            screens: {
              CO2CalculatorScreen: 'calculator',
            },
          },
          ProfileSettings: {
            screens: {
              ProfileSettingsScreen: 'settings',
            },
          },
        },
      },
//      Modal: 'modal',
//      Login: '*',
    },
  },
};

export default linking;
