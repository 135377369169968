export function textifyHtml(htmlString:string) {
    const temp= htmlString.replace(/<[^>]+>/g, '');

   

    return temp.replace(/&amp;/g, '&')
                .replace(/&#038;/g, '&')
                .replace(/&nbsp;/g, ' ')
                .replace(/\n{3,}/g, '\n\n');
  }
