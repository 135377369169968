import * as React from 'react';
import { Dimensions, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';

import { RootTabScreenProps } from '../types';

//import {FormBuilder} from 'react-native-paper-form-builder';
import {useForm} from 'react-hook-form';
import {Button} from 'react-native';
import { useContext, useReducer, useState } from 'react';
import { loginUser, logoutUser } from '../utils/AuthService';
import { useUserContext } from '../context/UserContext';

import { styles } from '../constants/stylesUG';

export default function LoginScreen() {
  const { user, dispatch } = useUserContext();
  //const [ loggedInStatus, setLoggedInStatus ] = useState((loggedInUser?loggedInUser.id>-1:false));

  const [loginFormUsername, onChangeUsername] = useState('');
  const [loginFormPassword, onChangePassword] = useState('');

  return (
    <View style={styles.container}>
        { user.id>-1?
      <ScrollView contentContainerStyle={styles.scrollView}>
      <Text style={styles.title}>Profile</Text>
        <Button
          title="Log out"
          onPress={() => {
            console.log('Logging out');
            const userData=logoutUser().then((userData) => 
            {
              console.log("Logged out");
              dispatch({type:'logoutUser'})
              //setLoggedInStatus(false);              
            })
      }} >
          Logout
        </Button>
        </ScrollView>
        :
        <ScrollView contentContainerStyle={styles.scrollView}>
        <Text style={styles.title}>Login</Text>
        <Text style={styles.formFieldLabelSmall}>Username</Text>
        <TextInput style={{ height: 40, margin: 12, borderWidth: 1, padding: 10 }} 
          onChangeText={onChangeUsername} 
          value={loginFormUsername}
          autoCorrect={false} 
          keyboardType={'email-address'} />
        <Text style={styles.formFieldLabelSmall}>Password</Text>
        <TextInput style={{ height: 40, margin: 12, borderWidth: 1, padding: 10 }} 
          onChangeText={onChangePassword} 
          value={loginFormPassword}
          autoCorrect={false} 
          keyboardType={'visible-password'} 
          secureTextEntry={true} />
        <Button
         title="Log in"
          onPress={() => {
            console.log('form data', loginFormUsername, loginFormPassword);

            const userData=loginUser(loginFormUsername,loginFormPassword).then((userData) => 
              {
                console.log("Logged in user "+userData.username);
                dispatch({ type:'loginUser', payload: { username: userData.username, token: userData.token, id: userData.id, }})
                //setLoggedInStatus(true);              
              }
              ).catch((e) => {
                console.log("Error login");
                console.log(e);
              }
            );

          }} >
          Login
        </Button>
        </ScrollView>
          }
    </View>
  );
}

/*
const styles = StyleSheet.create({
  formFieldLabelSmall: {
    height: 10,
    fontSize:12,
    fontWeight:'normal',
    textAlign:'left',
    color: 'white',
    textShadowColor: 'rgba(0, 0, 0, 0.75)',
    textShadowOffset: {width: -1, height: 1},
    textShadowRadius: 5
  
  },
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
  containerStyle: {
    width: Math.min(Dimensions.get('window').width,600),
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 10,
    flex: 1,

  },
  scrollViewStyle: {
    flex: 1,
    padding: 15,
    justifyContent: 'center',
    
  },
  headingStyle: {
    fontSize: 30,
    textAlign: 'center',
    marginBottom: 40,
  },
});
*/