import * as React from 'react';
import { Platform, StyleSheet, Image, ScrollView, Dimensions, Button, Linking, TouchableOpacity } from 'react-native';
import { useEffect, useState } from 'react';



import Carousel, { ItemObject } from 'react-elastic-carousel';

import { Text, View } from '../components/Themed';
import { fetchProducts, ProductInfo } from '../utils/UGAPIService'; //'../utils/WooCommerceService';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';
import { sourceURL } from '../constants/serverConfig';

const WIDTH=Dimensions.get('window').width;
const HEIGHT=Dimensions.get('window').height;

import { styles } from '../constants/stylesUG';
import { RemoveScroll } from 'react-remove-scroll';


function handleAddToCart(prodId:number) {
  // open add to cart link in browser window
  Linking.openURL(sourceURL+"/shop");
}

export default function ProductsScreen() {
  const [products, setProducts] = useState<ProductInfo[]>([]);
  const [expanded, setExpanded] = useState(false);
  const colorScheme = useColorScheme();
  
  useEffect(() => {
    const oldProducts=products.copyWithin(100,1);
    fetchProducts()
      .then((productInfos:ProductInfo[]) =>
        setProducts(productInfos))
      .catch((reason:any) => 
      console.log("Error: "+reason));
      setProducts(oldProducts);
  },[]);

/* SLIDER  const sliderSettings = {
    dots: true,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '0px',
    centerMode: true,
  };
      <Slider {...sliderSettings }>
*/

  function onChange(item: ItemObject, index:number) {
    console.log("onChange carousel index: "+index);
  }


  return (
    <RemoveScroll>
    <ScrollView style={{ background: "linear-gradient(#56949f,#005914)" }}>

    <View style={StyleSheet.flatten([styles.container, { width:Math.min(WIDTH,(WIDTH>640?720:600)), margin: 'auto', backgroundColor:'transparent'}])}>
      <Text style={styles.title}>Products</Text>

      <Carousel        
      showArrows={(WIDTH>640)}
      isRTL={false}
      onChange={onChange} 
 
        >
      { products.map(( item: ProductInfo, index: number ) => {
        return (
          <View style={StyleSheet.flatten([styles.containerCard, {borderWidth: 3, borderColor:'white', borderRadius: 20, width:Math.min(WIDTH-60,600), margin:0, padding:10, height:HEIGHT-180}])}  key={'t'+index}>
            <Text style={{...styles.productTitle}}>{item.name}</Text>
            <Button title='Add to cart' onPress={() => handleAddToCart(item.id)} />
     
          <ScrollView style={StyleSheet.flatten([styles.container,{ border:3,borderColor:'red',width: '100%'}]) }>
            <Text style={StyleSheet.flatten([styles.productText, { marginLeft:10, color: Colors[colorScheme].text,fontWeight:'bold' }])}>Description</Text>
            <Text style={StyleSheet.flatten([styles.productText, { margin: 10, color: Colors[colorScheme].text }])}>{item.description && item.description.substring(0,(expanded?2000:300))+'...'}</Text>
          {item.description && item.description.length>300?
            <TouchableOpacity style={{ height: 30, marginTop: 0, marginLeft:20 }}  onPress={() => {console.log("Read more pressed"); setExpanded(!expanded);}} >
              <Text style={{...styles.productText, color: Colors[colorScheme].text}} >{((item.description && item.description.length>300 && expanded==false?'  Show more':'  Show less'))}</Text>
            </TouchableOpacity>
          :
            null }
      
          { item.imageURLs?.map((u: string, index:number) => {
            return (
              <Image key={index} style={styles.productImage} source={{uri:u}} />
            )
          })}
        </ScrollView>
 
      </View>

          );
      })
    }
  </Carousel>


    </View>

    </ScrollView>
    </RemoveScroll>
  );
}


