/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { FontAwesome } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import * as React from 'react';
import { Text, ColorSchemeName, Pressable, TouchableOpacity, Platform } from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

import HomeScreen from '../screens/HomeScreen';
import CertificatesScreen from '../screens/CertificatesScreen';
import ProductsScreen from '../screens/ProductsWebScreen';
import LoginScreen from '../screens/LoginScreen';
import { LogoTitle } from '../components/LogoTitle';
import { RootStackParamList, RootTabParamList, RootTabScreenProps } from '../types';
import LinkingConfiguration from './LinkingConfiguration';
import { View } from 'react-native';
import {  UserContextProvider, useUserContext } from '../context/UserContext';
import { useContext } from 'react';
import CO2CalculatorScreen from '../screens/CO2CalculatorScreen';

import {RemoveScroll} from 'react-remove-scroll';
import ProductsWebScreen from '../screens/ProductsWebScreen';
import ProfileSettingsScreen from '../screens/ProfileSettingsScreen';


export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }) {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
        <LogoTitle />
      <RootNavigator />
    </NavigationContainer>
  );
}



/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();


function RootNavigator() {
  const { user, dispatch } = useUserContext();

  const colorScheme = useColorScheme();

  
  return (
    <Stack.Navigator>
      <Stack.Screen name="Root" 
        component={BottomTabNavigator} 
        options={({ navigation, route }) => 
          (
            { 
            headerTitle: props => <></>,
            headerShown: false,
          })
        } 
      />
    </Stack.Navigator>
  );
}

/**
 * A bottom tab navigator displays tab buttons on the bottom of the display to switch screens.
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */
const BottomTab = createBottomTabNavigator<RootTabParamList>();

function BottomTabNavigator() {
  const colorScheme = useColorScheme();
  const { user, dispatch } = useUserContext();

  return (
    <BottomTab.Navigator 
      initialRouteName="Home"
      screenOptions={{
        //tabBarActiveTintColor: Colors[colorScheme].tint,
        headerShown: false,
      }}>
      <BottomTab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          title: 'Home',
          headerShown: false,
          tabBarIcon: ({ color }) => <TabBarIcon name="home" color={color} />,
        }}
      />
      { user && user.id>-1 &&
      <>
      <BottomTab.Screen
        name="Products"
        component={(Platform.OS!=='web'?ProductsScreen:ProductsWebScreen)}
        options={{
          title: 'Plant',
          tabBarIcon: ({ color }) => <FontAwesome name="tree" color={color} size={24}/>,
        }}
      />
      <BottomTab.Screen
        name="Certificates"
        component={CertificatesScreen}
        options={({ navigation }: RootTabScreenProps<'Certificates'>) => ({
          title: 'Certificate',
          tabBarIcon: ({ color }) => <FontAwesome name="certificate" color={color} size={24}/>,
        })}
    />
    <BottomTab.Screen
        name="CO2Calculator"
        component={CO2CalculatorScreen}
        options={{
          title: 'CO2 Calculator',
          tabBarIcon: ({ color }) => <FontAwesome name="calculator" color={color} size={24}/>,
        }}
      />
    <BottomTab.Screen
        name="ProfileSettings"
        component={ProfileSettingsScreen}
        options={{
          title: 'Settings',
          tabBarIcon: ({ color }) => <FontAwesome name="cog" color={color} size={24}/>,
        }}
      />
    </>
}

    </BottomTab.Navigator>
  );
}

/**
 * You can explore the built-in icon families and icons on the web at https://icons.expo.fyi/
 */
function TabBarIcon(props: {
  name: React.ComponentProps<typeof FontAwesome>['name'];
  color: string;
}) {
  return <FontAwesome size={30} style={{ marginBottom: -3 }} {...props} />;
}
