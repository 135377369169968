import { Buffer } from 'buffer';
import { textifyHtml } from './CommonFunctions';

import { sourceURL } from '../constants/serverConfig';

export type PostInfo = {
  title: string;
  text: string;
  bgImage?: string;
  linkURL?: string;
  sticky?: boolean;
}


/*var buffer = Buffer.from("ck_eb08d29bc359a3048e39e4a18f381eed72c4019e" + ":" + "cs_af2eaae430fd535b3926a85014b4fce6e328b05f");
const creds=buffer.toString("base64");
const headers={ headers: {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'Basic '+creds
}}
*/
const headers={ headers: {
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}}

export const fetchPosts = async() => {
    console.log('Fetching posts from '+sourceURL+'/wp-json/wp/v2/posts?_fields=title,content&categories=23');
    const response = await fetch(sourceURL+'/wp-json/wp/v2/posts?_fields=title,content,sticky&categories=23',headers);
    //console.log('Get response '+response);
    const data = await response.json();
    // debug output of response data as json:     console.log(data);
    const newPosts:PostInfo[]=new Array();
    data.forEach((post:any) =>  {
      // debug:      console.log(post);
      // find img content source url from the post if exists
      const i=post.content.rendered.indexOf('<img');
      console.log('i='+i);
      let imgUrl='';
      if(i>0) {0
        const i2=post.content.rendered.indexOf('src=\"',i);
        console.log('i2='+i2);
        if(i<i2) {
          const i3=post.content.rendered.indexOf('\"',i2+5);
          console.log('i3='+i3);
          imgUrl=post.content.rendered.slice(i2+5,i3);
        }
      }
      console.log(imgUrl);
      const newPost:PostInfo = { title: post.title.rendered, text: textifyHtml(post.content.rendered),
        bgImage: (imgUrl===''?'':imgUrl), sticky: post.sticky };
      if(newPost.sticky) {
        newPosts.unshift(newPost);
        console.log("ADDED TO END");
        console.log(newPost);
      }
      else
      {
        newPosts.push(newPost);
        // 
        console.log("ADDED TO BEGINNING");
        console.log(newPost);
      }
    });
    return newPosts;
  };

