import { InitialState } from '@react-navigation/routers';
import * as React from 'react'

export type UserData = {
  id: number;
  username: string;
  token: string;
};

const initialUserContext={UserContextProvider,useUserContext};

type UserDataActionPayload = {
  username: string,
  token: string,
  id: number,
}

export type UserDataAction = {
  type: string;
  payload?: UserDataActionPayload;
}


const initialState:UserData={ username: '', token: '', id:-1 }

//export 
const UserContext = React.createContext<{user: UserData; dispatch: React.Dispatch<UserDataAction>;}>(
  {
    user: initialState,
    dispatch: () => undefined,
  }
  );


function userReducer(loggedInUser:UserData, action:UserDataAction):UserData {
  console.log('userReducer called for '+action.type);
    switch (action.type) {
        case 'loginUser': {
            console.log("UserContext: Login user:  "+action.payload?.username);
            // this means user has been successfully authenticated 
            const newUser:UserData={username: (action.payload?action.payload.username:''), token: (action.payload?action.payload.token:''), id: (action.payload?action.payload.id:-1)};
      
            return newUser;
        }
        case 'logoutUser': {
            console.log("UserContext: Logging out user");
            return { username:'',token:'',id:-1 }
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function UserContextProvider( props: {children:React.ReactNode} ) {
    const [user, dispatch] = React.useReducer(userReducer, initialState)

    const value = {user, dispatch}
    return (
      <UserContext.Provider value={value}>
      {props.children}
      </UserContext.Provider>
    );

}

function useUserContext() {
    const context = React.useContext(UserContext);
    if(context===undefined) {
        throw new Error('useUserContext must be used withing a UserContextProvider')
    }
    return context;
}

export {UserContextProvider, useUserContext}
