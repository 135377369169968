import * as React from 'react';
import { useState } from 'react';
import { Dimensions, StyleSheet, Image, Button, ScrollView } from 'react-native';
//import { Title, Paragraph, Card } from 'react-native-paper';
//import {Button} from 'react-native-paper';

import { Text, View } from '../components/Themed';
import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

import { RootTabScreenProps } from '../types';



const WIDTH=Math.min(Dimensions.get('window').width,600);
const HEIGHT=Dimensions.get('window').height;

import { styles } from '../constants/stylesUG';


import { TextInput } from 'react-native';
import { RemoveScroll } from 'react-remove-scroll';



export default function CO2CalculatorScreen({ navigation }: RootTabScreenProps<'CO2Calculator'>) {
  const [totalCO2e, setTotalCO2e] = useState(0.0);
  const [vehiclesTotalCO2e, setvehiclesTotalCO2e] = useState(0.0);
  const [flightsTotalCO2e, setFlightsTotalCO2e] = useState(0.0);

  const [petrolVehicleMileageString, onPetrolVehicleMileage] = useState('');
  const [dieselVehicleMileageString, onDieselVehicleMileage] = useState('');

  const [domesticFlightsMileageString,onDomesticFlightsMileage] = useState('');
  const [continentalFlightsMileageString,onContinentalFlightsMileage] = useState('');
  const [interContinentalFlightsMileageString,onIntercontinentalFlightsMileage] = useState('');


  const [phase,setPhase] = useState('Vehicles');


  function parseFloatOrZero(text:string) {
    const num=parseFloat(text);
    return (num)?num:0;    
  }


  const calcCO2 = () => {
    const vehiclesCO2e=parseFloatOrZero(petrolVehicleMileageString)*1/8.47458+parseFloatOrZero(dieselVehicleMileageString)*1/10.30928;
    setvehiclesTotalCO2e(vehiclesCO2e);

    const flightsCO2e=
      parseFloatOrZero(domesticFlightsMileageString)*1/4.63963 +
      parseFloatOrZero(continentalFlightsMileageString)*1/5.54353 +
      parseFloatOrZero(interContinentalFlightsMileageString)*1/7.25109;
    setFlightsTotalCO2e(flightsCO2e);

    setTotalCO2e(vehiclesCO2e+flightsCO2e);
  };

  
  return (
    <RemoveScroll>
    <ScrollView style={{ background: "linear-gradient(#56949f,#005914)" }}>
    <View style={styles.container} >
          <View style={styles.containerCard}>
        <View style={StyleSheet.flatten([styles.container,{ flexDirection:'row',flex:1}])} >       
        <Text style={StyleSheet.flatten([styles.productTitle,{ fontSize: 20, flex:1,textAlign:'left',marginLeft:10 }])}>TOTAL</Text>
        <Text style={StyleSheet.flatten([styles.productTitle,{ fontSize: 20, flex:1,textAlign:'right',marginRight:20 }])}>{totalCO2e.toFixed(2)} CO2 kg/year</Text>
        </View>
      </View>
      <View style={styles.containerCard}>
        <View style={StyleSheet.flatten([styles.container,{ flexDirection:'row',flex:1}])} >       
        <Text style={StyleSheet.flatten([styles.productTitle,{ flex:1,textAlign:'left',marginLeft:10 }])}>vehicles</Text>
        { phase!=='Vechicles' &&
          <Button title='Edit' onPress={() => { calcCO2();setPhase('Vehicles');}} ></Button>
        }
        <Text style={StyleSheet.flatten([styles.productTitle,{ flex:1,textAlign:'right',marginRight:20 }])}>{vehiclesTotalCO2e.toFixed(2)} CO2 kg/year</Text>
        </View>
        { phase==='Vehicles'?
        <View style={{margin:10,flexDirection:'row'}} >
          <Text style={StyleSheet.flatten([styles.productText,{width: '60%',textAlign:'left'}])}>
            CO2e is calculate for mid-size family car.
</Text>
          <View style={{flexDirection:'column'}} >
            <View>
            <Text style={{...styles.productText,textAlign: 'left'}}>Petrol vehicles total mileage</Text>
              <TextInput style={styles.textInput} placeholder="km" onChangeText={onPetrolVehicleMileage}/>
            </View>
            <View>
            <Text style={{...styles.productText,textAlign: 'left'}}>Diesel vehicles total mileage</Text>
              <TextInput style={styles.textInput} placeholder="km" onChangeText={onDieselVehicleMileage}/>
            </View>
            <Button title='Submit' onPress={() => { calcCO2();setPhase('Flights');}} ></Button>
          </View>
        </View> 
        :
        null }
      </View>
      <View style={styles.containerCard}>
      <View style={StyleSheet.flatten([styles.container,{ flexDirection:'row',flex:1}])} >       
        <Text style={StyleSheet.flatten([styles.productTitle,{ flex:1,textAlign:'left',marginLeft:10 }])}>Flights</Text>
        { phase!=='Flights' &&
          <Button title='Edit' onPress={() => { calcCO2();setPhase('Flights');}} ></Button>
        }
        <Text style={StyleSheet.flatten([styles.productTitle,{ flex:1,textAlign:'right',marginRight:20 }])}>{flightsTotalCO2e.toFixed(2)} CO2 kg/year</Text>
        </View>
        { phase==='Flights'?
        <View style={{margin:10,flexDirection:'row'}} >
          <Text style={StyleSheet.flatten([styles.productText,{width: '60%',textAlign:'left'}])}>
Lentojen laskenta huomioi tässä vain polttoaineenkulutuksesta lasketun CO2 ekv.-päästön. Yksittäisen lentomatka päästöt riippuvat monista lähtöoletuksista kuten täyttöasteista, kalustosta, päästöjen allokoinnista matkustajien ja rahdin kesken sekä korkealla ilmakehässä syntyvien pilvien vaikutuksen huomioinnista. Tämän vuoksi eri lentolaskureiden antamat tulokset vaihtelevat. Esimerkiksi reitti- ja lomalentojen matkustajakilometriä kohden lasketut päästöt eroavat toisistaan täyttöasteen erojen vuoksi. Loma-, eli tilauslentojen, täyttöaste on tyypillisesti korkeampi. Oheisissa laskentakertoimissa on käytetty Lipaston painotettua keskiarvoa reitti- ja lomalennoista. Uudella kalustolla lennettäessä päästöt per matkustajakilometri ovat tyypillisesti alemmat kuin vanhalla. Myöskään lentokoneiden valmistuksesta koituvia päästöjä ei ole huomioitu, mutta ne ovat pitkällä tähtäimellä alhaisemmat kuin autoilun osalta, koneiden käyttöikä on myös yleensä pidempi.
</Text>
          <View style={{flexDirection:'column'}} >
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>Domestic flights total mileage</Text>
              <TextInput style={styles.textInput}  placeholder="km" onChangeText={onDomesticFlightsMileage}/>
            </View>
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>Continental flights total mileage</Text>
              <TextInput style={styles.textInput}  placeholder="km" onChangeText={onContinentalFlightsMileage}/>
            </View>
            <View>
              <Text style={{...styles.productText,textAlign: 'left'}}>Intercontinental flights total mileage</Text>
              <TextInput style={styles.textInput}  placeholder="km" onChangeText={onIntercontinentalFlightsMileage}/>
            </View>
            <Button title='Submit' onPress={() => { calcCO2();setPhase('None');}} ></Button>
          </View>
        </View>
        :
        null
        } 
      </View>

 
<Text>
          Yrityksen kysymykset:
Laskukaava on se, että annettu luku (esim. kilometriä), jaetaan ohessa mainitulla jakoluvulla ja tulokseksi tulee CO2 kg
Ehdotan, että Lopulta kaikkien kysymysten jälkeen laskuri antaa vastaukseksi vain yhden luvun. Tuolloin meidän mittalukuja on hankalampi seurata, koska kaikki on kuitenkin mahdollista tehdä vain sinnepäin. Jos jokin osa-alue jätetään pois, se mainitaan lopputuloksessa.
KULKUNEUVOT
Ajoneuvot:
Yksinkertaisimmillaan autoilun päästöt voidaan laskea vuoden kilometrimäärän ja keskimääräisen matkustajamäärän perusteella. Tällöin autolla ajon polttoaineenkulutuksen päästöinä käytetään 200 CO2 ekv /ajoneuvokm.
Autoilun hiilijalanjälki lasketaan vuotuisen kilometrimäärän, auton energiankulutuksen, ja auton keskimääräisen käyttäjämäärän perusteella. Henkilöauton kuormituksen (henkilömäärän, sisältäen kuljettaja) oletusarvona on valtakunnallinen keskiarvo 1,7.
Oletuksena tässä laskurissa on, että autossa on yksi henkilö autossa ja kyseessä on pieni perheauto ja auton vuosimalli on 2007.
Kysytään kilometrit
- Bensiini / Esimerkki: Jaetaan annetut kilometrit luvulla 8,47458 ja saadaan CO2 (kg)
- Diesel/ Jaetaan 10,30928 Sähköautot lisäksi KWH
Todellisuudessa, se kuinka monta henkilöä käyttää autoa vaikuttaa näihin lukuihin, nyt oletus 1,7.
Todellisuudessa, se mikä on auton oikea energiankulutus vaikuttaa, ellei pystytä antamaan tarkkoja bensiinilukuja, joka voi olla hankalampaa löytää kuin kilometrit joita automaattisesti mitataan.
Myös se mitä hiilipäästöjä tulee auton rakennusvaiheessa, vaikuttaa näihin lukuihin.
Kuorma-autot:
Lennot:
Kysytään kilometrit
- Kotimaan lennot / Jaetaan 4,63963
- Ulkomaan lennot / Jaetaan 5,54353
- Mannerten väliset lennot / Jaetaan 7,25109
Lentojen laskenta huomioi tässä vain polttoaineenkulutuksesta lasketun CO2 ekv.-päästön. Yksittäisen lentomatka päästöt riippuvat monista lähtöoletuksista kuten täyttöasteista, kalustosta, päästöjen allokoinnista matkustajien ja rahdin kesken sekä korkealla ilmakehässä syntyvien pilvien vaikutuksen huomioinnista. Tämän vuoksi eri lentolaskureiden antamat tulokset vaihtelevat. Esimerkiksi reitti- ja lomalentojen matkustajakilometriä kohden lasketut päästöt eroavat toisistaan täyttöasteen erojen vuoksi. Loma-, eli tilauslentojen, täyttöaste on tyypillisesti korkeampi. Oheisissa laskentakertoimissa on käytetty Lipaston painotettua keskiarvoa reitti- ja lomalennoista. Uudella kalustolla lennettäessä päästöt per matkustajakilometri ovat tyypillisesti alemmat kuin vanhalla. Myöskään lentokoneiden valmistuksesta koituvia päästöjä ei ole huomioitu, mutta ne ovat pitkällä tähtäimellä alhaisemmat kuin autoilun osalta, koneiden käyttöikä on myös yleensä pidempi.
Tähän osioon voidaan myös lisätä julkinen liikenne, laivat ja moottoripyörät. 
Polttoöljyn lämpöarvo on eri yksiköillä ilmaistuna noin 10 kWh/litra eli 11,8 kWh/kg tai 42,7 MJ/kg.
          </Text>
          </View>
          </ScrollView>
          </RemoveScroll>
  
  );
}

