import { Buffer } from 'buffer';
import { textifyHtml } from './CommonFunctions';
//import { sourceURL } from '../constants/serverConfig';

const sourceURL = 'https://ug-api.cappgw.com';

export type ProductInfo = {
    id: number;
    name: string;
    description?:string;
    price: number;
    co2e?:number;
    location?:string;
    url?:string;
    imageURLs?: string[];
  }

const headers={ headers: {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': ''
}}

export const fetchProducts = async() => {
    console.log('Fetching products from '+sourceURL+'/ug/products');
    // TODO: add authorization
    const response = await fetch(sourceURL+'/ug/products',headers);
    //console.log('Get response '+response);
    const data = await response.json();
    //console.log(data);
    const productInfos:ProductInfo[]=data;

    return productInfos;
    //setProducts(productInfos);
  };

  export type OrderInfo = {
    id: number;
    orderDate: Date;
    productId: number;
    productName: string;
    quantity: number;
    status: string;
    latitude?: number;
    longitude?: number;
    pcs?: number;
    productCO2e?: number;
  }

export const fetchOrders = async(token:string) => {
    headers.headers.Authorization='Bearer '+token;
    // will add sorting by largest order first and limit responses in URL already
    const fetchUrl=sourceURL+'/ug/orders';
    console.log('Fetching orders from '+fetchUrl);
    const response = await fetch(fetchUrl,headers);
    const data = await response.json();
    // debug output of response content as json:    
    console.log(data);
    const orderInfos:OrderInfo[]=data;

    return orderInfos;
};